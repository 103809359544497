<template>
    <div>
        <!-- This component will display the Google Form embedded in an iframe -->
        <iframe width="100%"
                height="600px"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                :src="googleFormUrl">
            Loading...
        </iframe>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                googleFormUrl: "https://docs.google.com/forms/d/e/1FAIpQLSdaW1y89zYhoejQT7QxBHsUH_Yo_T68LWA-mcGodcH1dIn_dg/viewform?usp=sf_link"
            };
        }
    };
</script>

<style scoped>
 
</style>

