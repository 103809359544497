<template>
    <div class="col wow fadeIn" data-wow-delay="0.1s">
        <div class="bg-light rounded p-5">
           
            <!-- Replace the form with Google Form embed -->
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfiM6mmx4-deCJNGFMx07nXS7m3NT0woP8watPHRe18FKiNHw/viewform?embedded=true" width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading�</iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        // You can remove the data section if you're not using formData anymore
    }
</script>

<style scoped>
    /* Your styling remains the same */
    .btn.btn-primary {
        background-color: #05A3A4;
        border-color: #05A3A4;
    }

    .rounded-pill {
        color: #006373;
    }
</style>
